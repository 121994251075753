<template>
<div >
  <div style="margin: 20px 0 0 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="service-user">
    <Button type="primary" size="large" @click="add">+ 新增客服</Button>
    <div class="table">
      <Table :columns="columns" :data="dataList"></Table>
    </div>

  </div>
  <!-- <Modal
      v-model="modal"
      :title="type == 'edit' ? '编辑客服' : '新增客服'"
      width="1100px"
  >
    <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="100px">
      <el-form-item label="用户选择" prop="userId">
        <el-select v-model="formValidate.userId"
                   ref="select"
                   remote
                   :remote-method="remoteMethod"
                   filterable class="w300" placeholder="请选择用户"
                   clearable
        >
          <el-option v-for="(item,index) in userList" :value="item.id" :key="item.id" :label="item.nickname">{{ item.nickname }}(ID:{{item.id}})</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile">
        <el-input v-model="formValidate.mobile" size="large" placeholder="请输入手机号/400电话" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="联系二维码" prop="uploadfileId">
        <el-upload :action="constant.URL + '/uploadfile/upload'"
                :data="{
                      type:3
                    }"
                accept=".jpg,.png,.jpeg,.gif"
                :headers="{
                      'access-token':Cookies.get('liteAccessToken')
                    }"
                :on-success="uploadFileSuccess"
                   :show-file-list="false"
        >
          <div class="upload-btn" v-if="!uploadImg">
            <Icon type="ios-add" :size="50" />
          </div>
          <img :src="uploadImg" width="120" height="120" v-else />
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <Button @click="closeModal">取消</Button>
      <Button type="primary" @click="confirm">确定</Button>
    </div>
  </Modal> -->
  <DeleModal :status="deleModalStatus" txt="确定删除吗" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  <el-drawer :size="800" :visible.sync="modal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">{{ type == 'edit' ? '编辑客服' : '新增客服' }}</div>
      <div class="drawer-title-btns">
        <Button @click="closeModal" style="margin-right: 10px;">取消</Button>
        <Button type="primary" @click="confirm">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="100px">
        <el-form-item label="用户选择" prop="userId">
          <el-select v-model="formValidate.userId"
                    ref="select"
                    remote
                    :remote-method="remoteMethod"
                    filterable class="w300" placeholder="请选择用户"
                    clearable
          >
            <el-option v-for="(item,index) in userList" :value="item.id" :key="item.id" :label="item.nickname">{{ item.nickname }}(ID:{{item.id}})</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model="formValidate.mobile" size="large" placeholder="请输入手机号/400电话" class="w300"></el-input>
        </el-form-item>
        <el-form-item label="联系二维码" prop="uploadfileId">
          <el-upload :action="constant.URL + '/uploadfile/upload'"
                  :data="{
                        type:3
                      }"
                  accept=".jpg,.png,.jpeg,.gif"
                  :headers="{
                        'access-token':Cookies.get(constant.tokenName)
                      }"
                  :on-success="uploadFileSuccess"
                    :show-file-list="false"
          >
            <div class="upload-btn" v-if="!uploadImg">
              <Icon type="ios-add" :size="50" />
            </div>
            <img :src="uploadImg" width="120" height="120" v-else />
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "serviceUser",
  data(){
    const validateUpload = (rule, value, callback)=>{
      this.$nextTick(()=>{
        if (!this.uploadImg) {
          return callback(new Error('请上传联系二维码'));
        }else{
          callback();
        }
      })
    }
    return{
      Cookies:Cookies,
      columns:[
        {
          title: '名称',
          render: (h, para) => {
            return h('span', para.row.user && para.row.user.nickname);
          }
        },
        {
          title: '联系方式',
          key: 'mobile'
        },
        {
          title: '联系二维码',
          render: (h, para) => {
            return h('img', {
              attrs: {
                src: para.row.qr_code,
                width:82,
                height:82
              },
              style: {
                margin: '10px',
              },

            }, '');
          }
        },
        {
          title: '创建时间',
          render:(h,para)=>{
            return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.edit()
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.delete()
                  }
                }
              }, '删除')
            ]);
          }
        },
      ],
      dataList:[],
      modal:false,
      formValidate: {
        userId: '',
        mobile: '',
        uploadfileId: '',
      },
      ruleValidate: {
        userId: [
          { required: true, message: '请选择用户', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入手机号/400电话', trigger: 'blur' }
        ],
        uploadfileId: [
          { required: true, message: '请上传联系二维码',validator: validateUpload, }
        ]
      },
      userList:[],
      uploadImg:'',
      timer:null,
      type:'',
      curData:{},
      deleModalStatus:false,
      modalLoading:false,
    }
  },
  components:{
    DeleModal
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      this.api.customer.serviceList({
        site_id:"-1"
      }).then((res)=>{
        this.dataList = res.list;
      })
    },
    remoteMethod (query) {
      if (query !== '') {
        if(this.timer) return;
        this.timer = setTimeout(()=>{
          console.log('11111')
          this.getUserList(query);
          this.timer = null
        },500)
      } else {
        this.userList = [];
      }
    },
    getUserList(query,name) {
      let params = {
        pageSize: 20,
        page: 1,
        keyword: query,
        site_id:-1
      };
      this.api.production.userList(params).then((res) => {
        this.userList = res.list;
      })
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.formValidate.uploadfileId = response.data.info.id;
      this.uploadImg = response.data.info.upload_path;
    },
    closeModal(){
      this.modal = false;
    },
    confirm(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            customer_user_id:this.formValidate.userId,
            uploadfile_id:this.formValidate.uploadfileId,
            mobile:this.formValidate.mobile
          };
          if(this.type == 'edit'){
            params.id = this.curData.id;
            this.api.customer.serviceUpdate(params).then((res)=>{
              this.$Message.success('操作成功!');
              this.modal = false;
              this.getList();
            })
          }else{
            this.api.customer.serviceCreate(params).then((res)=>{
              this.$Message.success('操作成功!');
              this.modal = false;
              this.getList();
            })
          }

        }
      })
    },
    add(){
      this.type = 'add';
      this.modal = true;
      this.formValidate.userId = '';
      this.formValidate.uploadfileId = '';
      this.formValidate.mobile = '';
      this.uploadImg = '';
    },
    edit(){
      this.type = 'edit';
      this.modal = true;
      this.formValidate.userId = this.curData.user_id;
      this.getUserList();
      this.formValidate.uploadfileId = '';
      this.formValidate.mobile = this.curData.mobile;
      this.uploadImg = this.curData.qr_code;
    },
    delete(){
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curData.id
      }
      this.modalLoading = true;
      this.api.customer.serviceDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.service-user{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .table{
    margin-top: 20px;
  }
}
.upload-btn{
  width: 120px;
  height: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
